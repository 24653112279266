import { darkScrollbar } from '@mui/material'
import { createTheme, Theme } from '@mui/material/styles'

declare module '@mui/material/styles' {
    // es-lint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#21d3ed',
            dark: '#0891b3',
            contrastText: '#ffffff',
        },
        text: {
            primary: '#777777',
        },
        secondary: {
            main: '#21d3ed',
            dark: '#31a3d9',
        },
        // background: {
        //     default: '#0f172a',
        //     paper: '#0f172a',
        // },
    },
    typography: {
        fontFamily: 'Inter',
        fontWeightLight: 200,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 900,
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    // backgroundColor: '#0f172a',
                    backgroundColor: '#ffffff',
                    color: '#888888',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    width: '100%',
                    height: '100%',
                },
                body: {
                    // backgroundColor: '#0f172a',
                    // backgroundColor: '#ffffff',
                    backgroundColor: '#f9fafc',
                    width: '100%',
                    height: '100%',
                    // border: '5px solid gray',
                },
            },
        },
    },
})

export default theme
