import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import logo from './logo.svg'
// import './App.css'

function App() {
    return (
        <>
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                sx={{
                    height: '100%',
                    // border: '1px solid white',
                }}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    // alignItems='center'
                    sx={{
                        p: { xs: '1.5rem', sm: '3rem' },
                        m: '1rem',
                        border: '1px solid #f3f4f6',
                        borderRadius: '.15rem',
                        textAlign: { sm: 'center' },
                        color: '#f3f4f6',
                    }}
                >
                    <Typography variant='h3'>Paperlight Studio</Typography>
                    <Typography variant='h6' fontWeight='fontWeightRegular'>
                        Crafters of fine Nostr experiences.
                    </Typography>
                </Box>
                <Typography variant='subtitle1'>
                    &copy; 2023 Paperlight Studio LLC
                </Typography>
            </Box>
        </>

        // <div className='App' style={{ border: '1px solid white' }}>
        //     <header
        //         className='App-header'
        //         style={{ fontWeight: 'lighter', border: '2px solid #fff' }}
        //     >
        //         <div
        //             style={{
        //                 padding: '1rem 5rem 1rem 5rem',
        //                 border: '1px solid white',
        //                 borderRadius: '.15rem',
        //             }}
        //         >
        //             <h1 style={{ fontWeight: 'normal', lineHeight: '1rem' }}>
        //                 Paperlight Studio
        //             </h1>
        //             <p>Crafters of fine nostr experiences.</p>
        //         </div>
        //         <p style={{ fontSize: '.75rem' }}>
        //             &copy; 2023 Paperlight Studio LLC
        //         </p>
        //     </header>
        // </div>
    )
}

export default App
